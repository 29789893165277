// @ts-nocheck
import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import token1 from '../../assets/images/token-list/token1.png'
import token2 from '../../assets/images/token-list/token2.png'
import token3 from '../../assets/images/token-list/token3.png'
import token4 from '../../assets/images/token-list/token4.png'
import token5 from '../../assets/images/token-list/token5.png'
import token6 from '../../assets/images/token-list/token6.png'
import token7 from '../../assets/images/token-list/token7.png'
import token8 from '../../assets/images/token-list/token8.png'
import token9 from '../../assets/images/token-list/token9.png'
import token10 from '../../assets/images/token-list/token10.png'

// 定义旋转动画
const tokenRotateAnimation = keyframes`
  0% {
    transform: rotate(-22deg);
  }
  100% {
    transform: rotate(22deg);
  }
`

// 定义浮动动画
const tokenFloatAnimation = keyframes`
  0% {
    transform: translateY(-8px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(-8px);
  }
`

// 背景容器
const BgWrap = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;
`

// 浮动动画容器
const BoxWrap = styled.div<{ top: number; left: number }>`
  animation: ${tokenFloatAnimation} 5.875s linear infinite alternate;
  transform-origin: center center;
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
`

// 动画盒子
const Box = styled.div`
  animation: ${tokenRotateAnimation} 5.128s infinite alternate;
  transform-origin: center center;
`

// 子元素样式
const Item = styled.div<StyledDivProps>`
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  position: relative;
  background-size: cover;
  background-position: center center;
  transition: filter 0.15s ease-in-out, opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  will-change: filter, opacity, transform;
  filter: blur(7.93651px);
  background-image: ${props => `url(${props.img})`};
  width: 63px;
  height: 63px;
  background-color: ${props => props.color};
  border-radius: 50%;
  opacity: 0.764504;
  cursor: pointer;

  &:hover {
    filter: blur(0);
    opacity: 1;
    transform: scale(1.2) rotate(51deg);

    // 控制边框动画
    & > div.border1 {
      transform: scale(1.2);
      opacity: 0.3;
    }
    & > div.border2 {
      transform: scale(1.4);
      opacity: 0.1;
    }
  }
`

// 边框样式
const Border = styled.div`
  position: absolute;
  width: 63px;
  height: 63px;
  border-radius: 50%;
  border: 1px solid ${props => props.color};
  transform: scale(1);
  opacity: 0;
  transform-origin: center center;
`

const Border1 = styled(Border)`
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
`

const Border2 = styled(Border)`
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
`

interface StyledDivProps {
  img: string
  color?: string
}

// BoxWrap 子组件
interface FloatingBoxProps {
  top: number
  left: number
  img: any
  color: string
}

const FloatingBox: React.FC<FloatingBoxProps> = ({ top, left, img, color }) => {
  return (
    <BoxWrap top={top} left={left}>
      {/* <img src={img} alt="" /> */}
      <Box>
        <Item img={img} color={color}>
          <Border1 color={color} className="border1" />
          <Border2 color={color} className="border2" />
        </Item>
      </Box>
    </BoxWrap>
  )
}

// 主组件
const Screen: React.FC = () => {
  // const boxCount = 20 // 生成多个 BoxWrap
  // const elementSize = 63 // 图片尺寸
  // const [positions] = useState(() => {
  //   const screenWidth = window.innerWidth;
  //   const screenHeight = window.innerHeight;
  //   return generateNonOverlappingPositions(boxCount, elementSize, screenWidth, screenHeight);
  // });
  const [list] = useState([
    { img: token1, top: 50, left: 200, color: '#7E88C2' },
    { img: token2, top: 150, left: 700, color: '#F89E2B' },
    { img: token3, top: 350, left: 300, color: '#ED193E' },
    { img: token4, top: 450, left: 1000, color: '#191919' },
    { img: token5, top: 560, left: 500, color: '#3BAA89' },
    { img: token6, top: 660, left: 800, color: '#3E81CF' },
    { img: token7, top: 700, left: 200, color: '#F3C143' },
    { img: token8, top: 980, left: 1000, color: '#476EA7' },
    { img: token9, top: 680, left: 2000, color: '#002FB5' },
    { img: token10, top: 1000, left: 3000, color: '#000000' },
    { img: token1, top: 900, left: 3000, color: '#7E88C2' },
    { img: token2, top: 600, left: 1610, color: '#F89E2B' },
    { img: token3, top: 540, left: 3000, color: '#ED193E' },
    { img: token4, top: 600, left: 1200, color: '#191919' },
    { img: token5, top: 400, left: 1500, color: '#3BAA89' },
    { img: token6, top: 800, left: 200, color: '#3E81CF' },
    { img: token7, top: 400, left: 2500, color: '#F3C143' },
    { img: token8, top: 380, left: 2000, color: '#476EA7' },
    { img: token9, top: 200, left: 1760, color: '#002FB5' },
    { img: token10, top: 380, left: 2200, color: '#000000' }
  ])
  return (
    <BgWrap>
      {list.map((item, index) => (
        // <img src={item.img} alt="" />
        <FloatingBox key={index} top={item.top} left={item.left} img={item.img} color={item.color} />
      ))}
    </BgWrap>
  )
}

export default Screen
