import { tokAbi, tokContract, tokRecipient } from './tokConfig'
import { trxAbi, trxContract, trkRecipient } from './trxConfig'
import { Web3Provider } from '@ethersproject/providers'
import { ethers } from 'ethers'
export { tokRecipient, trkRecipient }
let provider = ''
let signer = ''
if (window.ethereum) {
  provider = new Web3Provider(window.ethereum)
  signer = provider.getSigner()
}

export async function getERC20Balance(walletAddress) {
  try {
    // 创建合约实例
    const contract = new ethers.Contract(tokContract, tokAbi, provider)

    // 调用 balanceOf 方法获取余额
    const balance = await contract.balanceOf(walletAddress)

    // 获取代币的 decimals 属性
    const decimals = await contract.decimals()

    // 格式化为用户可读单位
    return ethers.utils.formatUnits(balance, decimals)
  } catch (error) {
    console.error('获取余额失败:', error)
    throw new Error('获取余额失败，请检查合约地址或钱包地址！')
  }
}

export async function transferERC20(amount) {
  await provider.send('eth_requestAccounts', [])
  const sender = await signer.getAddress()
  try {
    const contract = new ethers.Contract(tokContract, tokAbi, provider)
    const decimals = await contract.decimals()
    const amountInUnits = ethers.utils.parseUnits(amount.toString(), decimals)
    const daiWithSigner = contract.connect(signer)

    console.log('开始转账...')
    const transferTx = await daiWithSigner.transfer(tokRecipient, amountInUnits)
    await transferTx.wait()
    console.log('转账成功')
  } catch (error) {
    console.error('转账失败:', error)
    throw new Error('转账失败，请检查授权或其他参数！')
  }
}

export async function transferTRC20(amount) {
  if (!window.tronWeb) {
    throw new Error('请安装 TronLink 并确保已登录！')
  }

  const tronWeb = window.tronWeb

  try {
    // 获取合约实例
    const contract = await tronWeb.contract().at(trxContract)

    // 调用 transfer 方法
    const transaction = await contract.transfer(trkRecipient, tronWeb.toSun(amount)).send()

    console.log('交易成功:', transaction)
    return transaction
  } catch (error) {
    console.error('转账失败:', error)
    throw new Error('转账失败，请检查合约地址、接收地址或金额！')
  }
}
export async function getTRC20Balance(walletAddress) {
  if (!window.tronWeb) {
    throw new Error('请安装 TronLink 并确保已登录！')
  }

  const tronWeb = window.tronWeb

  try {
    // 获取合约实例
    const contract = await tronWeb.contract().at(trxContract)
    // 调用 balanceOf 方法
    const balance = await contract.balanceOf(walletAddress).call()
    console.log( tronWeb.fromSun(balance));
    return tronWeb.fromSun(balance) // 转换为用户可读的单位
  } catch (error) {
    console.error('获取余额失败:', error)
    throw new Error('获取余额失败，请检查合约地址或钱包地址！')
  }
}

export async function getWalletAddress() {
  try {
    // 检查是否连接了钱包
    if (!window.ethereum) {
      throw new Error('no address')
    }
    // 获取用户地址
    const walletAddress = await signer.getAddress()

    console.log('address:', walletAddress)
    return walletAddress
  } catch (error) {
    console.error('get address error:', error)
  }
}

export async function estimateERC20Gas(inputAmount) {
  try {
    let address = await getWalletAddress()
    if (!address) {
      return
    }

    const contract = new ethers.Contract(tokContract, tokAbi, signer)

    // 获取代币的小数位数
    const decimals = await contract.decimals()

    // 转换用户输入为最小单位
    const amountInUnits = ethers.utils.parseUnits(inputAmount.toString(), decimals)

    // 使用 estimateGas 获取预计 gas
    const gasEstimate = await contract.estimateGas.transfer(tokRecipient, amountInUnits)

    return gasEstimate.toString()
  } catch (error) {
    console.error('获取 Gas 费用失败:', error)
    throw new Error('无法获取 Gas 费用，请检查输入金额或网络连接！')
  }
}
