// src/hooks/useNetworkSwitch.ts
import { useState } from 'react';
import { ethers } from 'ethers';

export const useNetworkSwitcher = (chainId: number, networkUrl: string) => {
  const [error, setError] = useState<string | null>(null);

  const switchNetwork = async () => {
    if (!window.ethereum) {
      setError('Ethereum provider is not available! Please install MetaMask.');
      return;
    }

    try {
      const ethereum = window.ethereum as any;

      // 请求切换网络
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ethers.utils.hexValue(chainId) }],
      });

      setError(null); // 重置错误
      console.log('Network switched successfully!');
    } catch (error) {
      // 如果网络切换失败，提示用户
      if (error.code === 4902) {
        console.error('This network is not available in MetaMask.');
        setError('This network is not available in MetaMask. Please add it.');
      } else {
        setError('Failed to switch network: ' + error.message);
      }
    }
  };

  const addNetwork = async () => {
    if (!window.ethereum) {
      setError('Ethereum provider is not available! Please install MetaMask.');
      return;
    }

    try {
      const ethereum = window.ethereum as any;

      // 添加自定义网络
      await ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: ethers.utils.hexValue(chainId), // 网络的 Chain ID
            chainName: 'TOK', // 网络名称
            rpcUrls: [networkUrl], // RPC URL
            nativeCurrency: {
              name: 'TOK',
              symbol: 'TOK',
              decimals: 18,
            },
            blockExplorerUrls: ['https://testnet.tokchain.org'], // 可选：区块浏览器 URL
          },
        ],
      });

      setError(null); // 重置错误
      console.log('Network added successfully!');
    } catch (error) {
      setError('Failed to add network: ' + error.message);
      console.error('Failed to add network:', error.message);
    }
  };

  return { switchNetwork, addNetwork, error };
};
