export default function formatLongNumber(number:any, decimalPlaces = 4) {
  number = number.toString()
  if (!number) {
    return '0'
  }
  const decimalIndex = number.indexOf('.')

  if (decimalIndex === -1) {
    return number
  }

  const integerPart = number.substr(0, decimalIndex)

  const decimalPart = number.substr(decimalIndex + 1)

  if (/^0+$/.test(decimalPart)) {
    return integerPart
  } else {
    let formattedDecimal = decimalPart.substring(0, decimalPlaces)
    formattedDecimal = formattedDecimal.replace(/0+$/, '')

    if (formattedDecimal.length > 0) {
      return integerPart + '.' + formattedDecimal
    } else {
      return integerPart
    }
  }
}