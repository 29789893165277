// @ts-nocheck
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TokLogo from '../../assets/images/logo.png'
import TrxLogo from '../../assets/images/trx.png'
import ChangeIcon from '../../assets/images/change.png'
import trcIcon from '../../assets/images/trc.png'
import ercIcon from '../../assets/images/erc.png'
import { CrossWrap, Currency, InputWrap, InfoData, Button } from './style'
import { tokRecipient, trkRecipient } from '../../web3/index'
import subAddress from '../../utils/subAddress'
import spinner from '../../assets/images/spinner.svg'
import {
  getTRC20Balance,
  getERC20Balance,
  transferTRC20,
  transferERC20,
  estimateERC20Gas,
  getWalletAddress
} from '../../web3/index'
declare global {
  interface Window {
    tronWeb: any
  }
}

export default function Cross() {
  const [tokAddress, setTokAddress] = useState('')
  const [trxAddress, setTrxAddress] = useState('')

  const [trxData, setTrxData] = useState({
    amount: 0,
    address: '',
    img: TrxLogo,
    icon: trcIcon,
    name: 'TRX'
  })

  const [tokData, setTokData] = useState({
    amount: 0,
    address: '',
    img: TokLogo,
    icon: ercIcon,
    name: 'TOK'
  })
  const [current, setCurrent] = useState({
    amount: 0,
    address: '',
    img: TokLogo,
    icon: ercIcon,
    name: 'TOK'
  })

  const [inputAmount, setInputAmount] = useState('')

  async function connectTronLink() {
    if (!window.tronWeb) {
      return
    }
    try {
      await window.tronWeb.request({ method: 'tron_requestAccounts' })
      let data = window.tronWeb.defaultAddress.base58
      setTrxAddress(data)
    } catch (error) {
      console.error('连接失败:', error)
    }
  }

  async function connectEthereum() {
    if (!window.ethereum) {
      console.error('MetaMask 未安装，请安装后再试！')
      return
    }
    try {
      // 请求用户授权连接钱包
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts'
      })
      console.log('以太坊地址:', accounts[0])
      return accounts[0] // 返回用户地址
    } catch (error) {
      console.error('连接失败:', error.message || error)
    }
  }

  const toggleCurrent = () => {
    if (current.name === 'TRX') {
      setCurrent(tokData) // 切换到 TOK
      getTokBalance() // 获取 TOK 余额
    } else {
      setCurrent(trxData) // 切换到 TRX
      getTRXBalance() // 获取 TRX 余额
    }
  }

  // 读取 TRX 余额
  async function getTRXBalance() {
    try {
      let result = await getTRC20Balance(trxAddress)
      setTrxData(prev => {
        const updatedTrxData = {
          ...prev,
          amount: Number(result)
        }
        if (current.name === 'TRX') {
          setCurrent(updatedTrxData)
        }
        return updatedTrxData
      })
    } catch (error) {
      console.log('读取TRX余额失败：', error)
    }
  }

  // 读取 TOK 余额
  async function getTokBalance() {
    try {
      let result = await getERC20Balance(tokAddress)
      setTokData(prev => {
        const updatedTokData = {
          ...prev,
          amount: Number(result)
        }
        if (current.name === 'TOK') {
          setCurrent(updatedTokData)
        }
        return updatedTokData
      })
    } catch (error) {
      console.log('读取TOK余额失败：', error)
    }
  }

  async function send() {
    return
    try {
      if (current.name === 'TOK') {
        await connectEthereum()
        await transferERC20(inputAmount)
      } else {
        await transferTRC20(inputAmount)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function handleInput(e: any) {
    const value = e.target.value
    if (/^\d*\.?\d*$/.test(value)) {
      if (Number(value) > Number(current.amount)) return
      setInputAmount(value) // 允许数字和小数点输入

      /*  try {
        let a =await estimateERC20Gas(value)
        console.log(a)
      } catch (error) {
        console.log(error)
      } */
    }
  }
  async function getTokAddress() {
    let address = await getWalletAddress()
    setTokAddress(address)
  }

  useEffect(() => {
    getTokAddress()
    setTimeout(() => {
      connectTronLink()
      getTokAddress()
    })
  }, [])

  useEffect(() => {
    getTRXBalance()
  }, [trxAddress])

  useEffect(() => {
    getTokBalance()
  }, [tokAddress])
  return (
    <CrossWrap>
      <Currency>
        <div className="symbol">
          <img src={current.img} alt="" />
          <div>
            <span>From</span>
            <span>{current.name}</span>
          </div>
        </div>
        <div className="change" onClick={toggleCurrent}>
          <img src={ChangeIcon} alt="" />
        </div>
        <div className="symbol">
          <img src={current.name === 'TRX' ? TokLogo : TrxLogo} alt="" />
          <div>
            <span>To</span>
            <span>{current.name === 'TRX' ? 'TOK' : 'TRX'}</span>
          </div>
        </div>
      </Currency>
      <InputWrap>
        <div className="top">
          <div className="title">
            Available：<span>{current.amount}</span>
          </div>
          <button onClick={() => setInputAmount(current.amount)}>MAX</button>
        </div>

        <div className="inputBox">
          <div>
            <img src={current.icon} />
            USDT
          </div>
          <input
            value={inputAmount} // 将状态值绑定到输入框
            onChange={e => {
              handleInput(e)
            }}
          />
        </div>
      </InputWrap>
      <InfoData>
        <div className="item">
          <span>Receive (On TOK)</span>
          <span>0 USDT</span>
        </div>

        <div className="item">
          <span>Address</span>
          <span>{subAddress(current.name === 'TRX' ? trkRecipient : tokRecipient)}</span>
        </div>

        {/* <div className="item">
          <span>Fee</span>
          <span>27.3598 {current.name}</span>
        </div> */}
      </InfoData>

      <Button status="Recharge" onClick={send} forbidden>
        {/* <img width={25} src={spinner} alt="" srcset="" /> */}
        <span>Coming Soon</span>
      </Button>
    </CrossWrap>
  )
}
