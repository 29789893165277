import request from '../plugins/axios'

export function Hot() {
  return request({
    url: '/api/Swap/Hot',
    method: 'POST'
  })
}

export function Search(params) {
  return request({
    url: '/api/Swap/Search',
    method: 'GET',
    params
  })
}

export function Pool() {
  return request({
    url: '/api/Swap/Pool',
    method: 'GET'
  })
}

export function Transactions() {
  return request({
    url: '/api/Swap/Transactions',
    method: 'GET'
  })
}

export function PoolTransactions() {
  return request({
    url: '/api/Swap/PoolTransactions',
    method: 'GET'
  })
}

export function TokenKLine(params) {
  return request({
    url: '/api/Swap/TokenKLine',
    method: 'GET',
    params
  })
}

export function Tokens(params) {
  return request({
    url: '/api/Swap/Tokens',
    method: 'GET'
  })
}

export function TvlK() {
  return request({
    url: '/api/Swap/TvlK',
    method: 'GET'
  })
}


export function TokenInfo(params) {
  return request({
    url: '/api/Swap/TokenInfo',
    method: 'GET',
    params
  })
}
