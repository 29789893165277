import { ChainId } from '@uniswap/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.TOKLOCAL]: '0x6D37C69A50e9B6daB0819A31fEbE8f36e8689f73',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.SEPOLIA]: '0x66828Ad13C08d4bf574Bf8363E8d197c3CeB43Dd',
  [ChainId.TOKTESTNET]: '0x3170422c3481Bd4b1cc034b14f313F340C06EaCf',
  [ChainId.TOKNET]: '0xEd324C465e928d207c4c763DDa836AE5bB7Ae5a7' //正式
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
