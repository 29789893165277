// @ts-nocheck

import { ChainId } from '@uniswap/sdk'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'

import styled from 'styled-components'

// import Logo from '../../assets/svg/logo.svg'
import Logo from '../../assets/images/logo.png'
import Tok from '../../assets/images/tok.png'
import tokLogo from '../../assets/images/logo.png'
import LogoDark from '../../assets/svg/logo_white.svg'
import Wordmark from '../../assets/svg/wordmark.svg'
import WordmarkDark from '../../assets/svg/wordmark_white.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import { YellowCard } from '../Card'
import Settings from '../Settings'
// import Menu from '../Menu'

import Row, { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
// import VersionSwitch from './VersionSwitch'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.5rem;
`};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

const TitleText = styled(Row)`
  width: fit-content;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const UniIcon = styled.div`
  display: flex;
  vertical-align: middle;
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img { 
      vertical-align: middle;
    }
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-end;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.TOKLOCAL]: 'TokLocal',
  [ChainId.TOKTESTNET]: 'Tok',
  [ChainId.TOKNET]: 'Tok',
  [ChainId.SEPOLIA]: 'Sepolia'
}
const Router = styled.div`
  margin-left: 40px;
  display: flex;
  align-items: center;
  a {
    margin-right: 20px;
    font-size: 18px;
    color: #7d7d7d;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: #222;
    }
  }
  .active {
    color: #222;
  }
  @media (max-width: 768px) {
    /* display: ; */
    margin-top: 2px;
    margin-left: 0px;
    a {
      font-size: 20px;
      margin-right: 10px;
    }
  }
`

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { pathname } = useLocation()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [isDark] = useDarkModeManager()

  return (
    <HeaderFrame>
      <RowBetween style={{ alignItems: 'flex-start' }} padding="1rem 1rem 0 1rem">
        <HeaderElement>
          <Title href=".">
            {/* <UniIcon>
              <img src={Logo} width={'23px'} alt="logo" />
            </UniIcon> */}
            <TitleText>
              <img style={{ width: '120px' }} src={Tok} alt="logo" />
            </TitleText>
          </Title>
          <Router>
            {isMobile && (
              <NavLink to={'/swap'}>
                <img style={{ width: '25px' }} src={tokLogo} alt="logo" />
              </NavLink>
            )}
            {!isMobile && (
              <NavLink to={'/swap'} className={pathname === '/swap' ? 'active' : ''}>
                Swap
              </NavLink>
            )}
            <NavLink to={'/explore'} className={pathname === '/explore' ? 'active' : ''}>
              Explore
            </NavLink>
            <NavLink to={'/bridge'} className={pathname === '/bridge' ? 'active' : ''}>
              Bridge
            </NavLink>
          </Router>
        </HeaderElement>
        <HeaderControls>
          <HeaderElement>
            <TestnetWrapper>
              {/* {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>} */}
            </TestnetWrapper>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {/* {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} ETH
                </BalanceText>
              ) : null} */}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            {
              // <VersionSwitch />
            }
            <Settings />
            {/* <Menu /> */}
          </HeaderElementWrap>
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  )
}
